import { encodeInUrl } from "../helper/helper";
import { BehaviorSubject } from "rxjs";

export const tokenSubject = new BehaviorSubject<String | null>(null);
export const refreshTokenSubject = new BehaviorSubject<String | null>(null);

export class ApiService {
  static baseUrl = process.env.REACT_APP_API_URL;

  static async get(url: string, param: any = null) {
    const token = tokenSubject.getValue();
    if (url.split("/").includes("public")) {
      return await fetch(this.baseUrl + url + encodeInUrl(param), {
        method: "GET",
      });
    }

    return await fetch(this.baseUrl + url + encodeInUrl(param), {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  }

  static async post(url: string, body: any, param: any = null) {
    const token = tokenSubject.getValue();
    let headers;
    if (token) {
      headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
    } else {
      headers = {
        "Content-Type": "application/json",
      };
    }

    return await fetch(this.baseUrl + url + encodeInUrl(param), {
      method: "POST",
      credentials: "include",
      headers: headers,
      body: JSON.stringify(body),
    });
  }

  static async patch(url: string, body: any, param: any = null) {
    const token = tokenSubject.getValue();
    let headers;
    if (token) {
      headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
    } else {
      headers = {
        "Content-Type": "application/json",
      };
    }

    return await fetch(this.baseUrl + url + encodeInUrl(param), {
      method: "PATCH",
      credentials: "include",
      headers: headers,
      body: JSON.stringify(body),
    });
  }

  static async delete(url: string, param: any = null) {
    const token = tokenSubject.getValue();
    let headers;
    if (token) {
      headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
    } else {
      headers = {
        "Content-Type": "application/json",
      };
    }

    return await fetch(this.baseUrl + url + encodeInUrl(param), {
      method: "DELETE",
      credentials: "include",
      headers: headers,
    });
  }

  static async uploadImage(file: File) {
    const token = tokenSubject.getValue();
    const formData = new FormData();

    formData.append("image", file);

    return await fetch(process.env.REACT_APP_API_URL + "/upload", {
      method: "POST",
      credentials: "include",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: formData,
    });
  }
}
