import Layout from "../../../components/UI/Layout/Layout";
import { useEffect, useRef, useState } from "react";
import getUserSubject, {
  getLeaderboard,
  saveBeerClicks
} from "../../service/user.service";
import { User } from "../../model/User";
import Leaderboard from "./Leaderboard/Leaderboard";
import RandomButton from "./RandomButton/RandomButton";

function Wei() {
  const [beerClick, setBeerClick] = useState<number>(0);
  const [isClicked, setIsClicked] = useState(false);
  const [leaderBoard, setLeaderBoard] = useState<User[]>([]);
  const [stillHereVisible, setStillHereVisible] = useState(false);
  const beerClickRef = useRef(beerClick); // Ref to store the latest beerClick value

  // Update the ref whenever the state changes
  useEffect(() => {
    beerClickRef.current = beerClick;
  }, [beerClick]);

  const handleClick = () => {
    if (!isClicked) {
      setIsClicked(true); // Grow the image
      setTimeout(() => {
        setIsClicked(false); // Shrink the image after 50ms
      }, 50);
    }
  };

  useEffect(() => {
    const subscription = getUserSubject().subscribe(u => {
      if (!u) {
        return;
      }
      setBeerClick(u.beerClicks);
      getLeaderboard()
        .then(response => response.json())
        .then(data => setLeaderBoard(data as User[]));

      const intervalId = setInterval(() => {
        saveClicks();
      }, 5 * 1000); // 5 seconds

      return () => {
        clearInterval(intervalId);
        subscription.unsubscribe(); // Unsubscribe when component unmounts
      };
    });
  }, []);

  const saveClicks = () => {
    saveBeerClicks(beerClickRef.current); // Use the ref value for saving clicks
    getLeaderboard()
      .then(response => response.json())
      .then(data => setLeaderBoard(data as User[]));
  };

  const beerClicker = () => (
    <div className={"w-full flex justify-center flex-col items-center min-w-96"}>
      <img
        className={`w-96 transition-transform duration-100 ease-in-out cursor-pointer ${
          isClicked ? "scale-110" : "scale-100"
        }`}
        src={`${process.env.PUBLIC_URL}/ressources/images/pinte.png`}
        onClick={() => {
          handleClick();
          setBeerClick(beerClick => beerClick + 1); // Functional update to ensure latest state
          if (beerClick % 10000 === 0) {
            setStillHereVisible(true);
          }
        }}
      />
      <div className={"flex justify-center text-5xl"}>{beerClick}</div>
    </div>
  );

  return (
    <div className={"bg-odm-cream"}>
      <Layout>
        <div className={"flex justify-center flex-col items-center mx-12 mt-6"}>
          <h2 className={"text-2xl text-center"}>Bienvenue sur notre Beer Clicker du WEI !</h2>
          <p className={"text-xl text-center mt-6"}>
            Clique pour obtenir le chiffre le plus haut de tous les participants au WEI ! Et pour pimenter le tout, toute forme de triche est autorisée ! Bonne chance !
          </p>
        </div>
        <div className={"flex flex-col md:flex-row "}>
          {stillHereVisible ? (
            <RandomButton setStillHereVisible={setStillHereVisible} />
          ) : (
            beerClicker()
          )}
          <div className={"flex justify-center mt-16 flex-col items-center mb-12 w-full"}>
            <h2 className={"text-3xl mb-12"}>LeaderBoard</h2>
            <Leaderboard leaderBoard={leaderBoard} />
          </div>
        </div>
      </Layout>
    </div>
  );
}

export default Wei;
