import React, { useState } from "react";
import "./Contact.scss";
import Layout from "../../../components/UI/Layout/Layout";
import Button from "../../../components/UI/Button/Button";
import clsx from "clsx";

const Contact = () => {
  const [prenom, setPenom] = useState("");
  const [nomDeFamille, setnomDeFamille] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const odmMail = "ordredumaltisep@gmail.com";
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    const mailtoLink = `mailto:${odmMail}?subject=Contact Form Submission&body=Name: ${prenom} ${nomDeFamille}%0D%0AEmail: ${email}%0D%0AMessage: ${message}`;
    window.location.href = mailtoLink;
    return;
  };

  return (
    <div className="bg-odm-cream">
      <Layout>
        <div className="grid grid-cols-12 justify-center items-center mt-8 flex-col">
          <div className={clsx(
            "w-full px-4 text-left mb-12",
            "col-start-2 col-end-12"
          )}>
            <h2 className="text-5xl">Nous contacter</h2>
            <p>
              Si vous avez la moindre demande, question ou réclamation ça se
              passe ici !
            </p>
          </div>
          <div className={clsx(
            "col-start-2 col-end-12"
          )}>
            <form onSubmit={handleSubmit}>
              <div>
                <div className="lg:flex flex-row pr-4">
                  <div className="flex flex-col lg:mr-4 flex-1">
                    <label className="mt-4">Prénom (obligatoire)</label>
                    <input
                      type="text"
                      id="prenom"
                      value={prenom}
                      onChange={(e) => setPenom(e.target.value)}
                      required
                      className="border border-black rounded-full bg-odm-cream px-2 py-1 w-full focus:outline-none focus:ring-2 focus:ring-odm-orange focus:border-transparent"
                    />
                  </div>
                  <div className="flex flex-col lg:ml-4 flex-none flex-1">
                    <label className="mt-4">Nom (obligatoire)</label>
                    <input
                      type="text"
                      id="name"
                      value={nomDeFamille}
                      onChange={(e) => setnomDeFamille(e.target.value)}
                      required
                      className="border border-black rounded-full bg-odm-cream px-2 py-1 w-full focus:outline-none focus:ring-2 focus:ring-odm-orange focus:border-transparent"
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col pr-6 flex-1">
                <label className={"mt-4"}>E-mail ISEP (obligatoire)</label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="border border-black rounded-full bg-odm-cream px-2 py-1 w-full focus:outline-none focus:ring-2 focus:ring-odm-orange focus:border-transparent"
                />

                <label className={"mt-4"}>Message (obligatoire)</label>
                <textarea
                  id="message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                  className="border border-black rounded-3xl bg-odm-cream px-2 py-1 w-full focus:outline-none focus:ring-2 focus:ring-odm-orange focus:border-transparent mb-4 h-40"
                ></textarea>

                <Button
                  className={"mb-8"}
                  type="submit"
                  text="Envoyer"
                  onClick={() => handleSubmit}
                />
              </div>
            </form>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default Contact;
