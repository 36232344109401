import React, { FC, useState } from "react";
import clsx from "clsx";
import { FieldError } from "react-hook-form";

type TextFieldProps = {
  value?: string;
  onValueChange?: Function;
  type?: "email" | "password" | "text";
  isInvalid?: FieldError;
} & React.ComponentPropsWithoutRef<"input">;

const TextField: FC<TextFieldProps> = ({
  value,
  onValueChange,
  type,
  isInvalid,
  ...props
}) => {
  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const [isFocused, setIsFocused] = useState(false);

  return (
    <div {...props}>
      <div
        className={clsx(
          "flex items-center rounded w-full h-full",
          `${
            isInvalid
              ? "border-2 outline-1 border-error"
              : "border-2 outline-1 border-grey"
          }`,
          `${
            isFocused && !isInvalid
              ? "border-2 outline-1 border-odm-orange"
              : !isInvalid
              ? "border-2 outline-1 border-grey"
              : ""
          }`
        )}
        onFocus={handleFocus}
        onBlur={handleBlur}
      >
        <input
          className={clsx("outline-0 h-full w-full rounded p-2")}
          onFocus={handleFocus}
          onBlur={handleBlur}
          type={type}
          onChange={(e) => {
            if (!onValueChange) {
              return;
            }
            onValueChange(e.target.value);
          }}
          value={value}
          placeholder={props.placeholder}
        />
      </div>
      <p className={"ml-2 text-xs text-error"}>
        {isInvalid ? isInvalid.message : ""}
      </p>
    </div>
  );
};

export default TextField;
