import { useEffect, useState } from "react";
import Layout from "../../../components/UI/Layout/Layout";
import { Product } from "../../model/Product";
import "./Shop.scss";
import ShopProduct from "../../../components/ShopProduct/ShopProduct";
import clsx from "clsx";
import getProductsSubject, {
  getProductBaseImage,
} from "../../service/product.service";
import { Oval } from "react-loader-spinner";

const Shop = () => {
  const [products, setProducts] = useState<Product[]>([]);

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    getProductsSubject().subscribe((products) => {
      if (!products) return;
      setProducts(products);
      setLoaded(true);
    });
  }, []);

  const outOfStock = !products.map((p) => p.disponibility <= 0).includes(false);

  const displayableProducts = products.filter(
    (p) => p.disponibility > 0 && p.shopVisible
  );

  if (!loaded) {
    return (
      <div className={"min-h-full bg-odm-cream pb-8"}>
        <Layout>
          <div className={"flex items-center justify-center mt-8"}>
            <Oval
              height="160"
              width="160"
              color="#fe9200"
              ariaLabel="oval-loading"
              wrapperStyle={{}}
              wrapperClass=""
              secondaryColor="#fe9200"
            />
          </div>
        </Layout>
      </div>
    );
  }

  return (
    <div className={"min-h-full bg-odm-cream pb-8"}>
      <div className={clsx("hidden text-grey")}></div>
      <Layout>
        <div
          className={clsx("flex flex-col", "lg:items-center lg:justify-center")}
        >
          {outOfStock ? (
            <div className="items-center justify-center flex h-[calc(100vh-50vh)]">
              <h1 className="text-center text-5xl">Pas de stock disponible</h1>
            </div>
          ) : (
            displayableProducts.map((p) => (
              <ShopProduct
                imageSource={getProductBaseImage(p)}
                productName={p.name}
                price={(Number(p.price) + Number(p.depositPrice)) as number}
                productDescription={p.description}
                productId={p.id}
                disponibility={p.disponibility}
                isVisible={p.shopVisible}
              />
            ))
          )}
        </div>
      </Layout>
    </div>
  );
};

export default Shop;
