import { BehaviorSubject } from "rxjs";
import { ApiService } from "./apiService";
import { Payment } from "../model/Payment";

export const cartSubject = new BehaviorSubject<Payment[] | null>(null);

export function addToCart(
  productId: number,
  userCode: string,
  quantity: number,
  container: string
) {
  ApiService.post("/product_orders/cart", {
    productId: productId,
    studentCode: userCode,
    quantity: quantity,
    containerType: container,
  })
    .then((response) => {
      if (response) response.json();
    })
    .then((data) => {
      const cart = data! as Payment[];
      cartSubject.next(cart);
    })
    .catch((error) => error);
}

export function removeFromCart(productId: number) {
  return ApiService.delete(`/product_orders/${productId}`);
}

export function getCart() {
  return ApiService.get("/payments/carts");
}

export function getCartSubject() {
  return cartSubject;
}

export function updateCartProductQuantity(
  productOrderId: number,
  quantity: number
) {
  return ApiService.patch(`/product_orders/${productOrderId}`, {
    quantity: quantity,
  });
}
