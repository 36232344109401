import { FC } from "react";
import "./Header.scss";
import { Options } from "../../../app/model/Options";
import { useLocation, useNavigate } from "react-router-dom";
import { User } from "../../../app/model/User";
import clsx from "clsx";
import { disconnect } from "../../../app/service/user.service";
import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuGroup,
  MenuDivider,
  useDisclosure,
} from "@chakra-ui/react";
import { Link as ChakraLink } from "@chakra-ui/react";
import { capitalize } from "../../../app/helper/helper";
import { CiShoppingCart } from "react-icons/ci";

interface HeaderProps {
  headerLink: Options[];
  user: User | null;
  background?: boolean;
  displayCart?: boolean;
}

const linkActive = (pathname: string, linkHeaderValue: string) => {
  const value = pathname.split("/").at(1);
  if (value === linkHeaderValue) {
    return true;
  }
  return false;
};

const Header: FC<HeaderProps> = ({ headerLink, user, background }) => {
  const { pathname } = useLocation();
  const { isOpen, onClose, onOpen, onToggle } = useDisclosure();
  const navigate = useNavigate();

  const handleLinkClick = (link: string) => {
    navigate(`/${link}`);
  };

  const handleCartOnClick = () => {
    navigate(`/panier`);
  };

  return (
    <div
      className={clsx(
        "flex items-center justify-between rounded-b-xl p-4 top-0 w-full z-50",
        background ? "bg-odm-cream bg-opacity-70" : ""
      )}
    >
      <div className="flex items-center w-fit">
        <img
          className={clsx("w-20 h-20 cursor-pointer mr-8", "lg:w-28 lg:h-28")}
          src={"/ressources/images/logo/logoODM.png"}
          onClick={() => navigate("/home")}
        />
        <div className="hidden md:flex items-center">
          {headerLink.map((link) => {
            return (
              <a
                className="mr-8 hover:font-bold hover:opacity-80 cursor-pointer"
                style={{
                  textDecoration: linkActive(pathname, link.value)
                    ? "underline"
                    : "none",
                }}
                onClick={() => handleLinkClick(link.value)}
              >
                {link.label}
              </a>
            );
          })}

          {user && user.role !== "ROLE_STUDENT" ? (
            <a
              onClick={() => navigate("/admin")}
              className="mr-8 hover:font-bold hover:opacity-80 cursor-pointer"
              style={{
                textDecoration: linkActive(pathname, "admin")
                  ? "underline"
                  : "none",
              }}
            >
              Admin
            </a>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="hidden md:flex items-center ml-auto pr-4">
        {user ? (
          <>
            <CiShoppingCart
              className="mr-3 cursor-pointer hover:opacity-80 hover:font-bold"
              size="2rem"
              onClick={() => handleCartOnClick()}
            />
            <Menu>
              <MenuButton className={"hover:underline"}>Mon Compte</MenuButton>
              <MenuList className="bg-odm-cream border-odm-brown border-2 drop-shadow-xl p-8 rounded-xl">
                <MenuItem className="mb-2 cursor-default">
                  {capitalize(user.name)} {capitalize(user.familyName)}{" "}
                  {user.promotion}
                </MenuItem>
                <MenuItem className="cursor-default">{user.email}</MenuItem>
                <div className="my-2" />
                <MenuDivider />
                <div className="my-2" />
                <MenuGroup>
                  <MenuItem
                    className={"hover:underline"}
                    onClick={() => {
                      disconnect();
                      navigate("/authentification");
                    }}
                  >
                    Se déconnecter
                  </MenuItem>
                </MenuGroup>
              </MenuList>
            </Menu>
          </>
        ) : (
          <a
            className="hover:font-bold hover:opacity-80"
            href={"/authentification"}
          >
            Connexion
          </a>
        )}
      </div>
      <div className={"flex"}>
        <CiShoppingCart
          className="md:hidden cursor-pointer mr-8"
          size="2rem"
          onClick={() => handleCartOnClick()}
        />
        <Menu onClose={onClose} onOpen={onOpen}>
          <MenuButton onClick={onToggle}>
            <div className="mr-8 flex items-center md:hidden">
              {isOpen ? <CloseIcon /> : <HamburgerIcon />}
            </div>
          </MenuButton>
          <MenuList className="bg-odm-cream border-odm-brown border-2 drop-shadow-xl p-8 rounded-xl">
            <MenuGroup>
              {headerLink.map((link) => (
                <MenuItem key={link.value}>
                  <a
                    className="mr-8"
                    style={{
                      textDecoration: linkActive(pathname, link.value)
                        ? "underline"
                        : "none",
                    }}
                    onClick={() => handleLinkClick(link.value)}
                  >
                    {link.label}
                  </a>
                </MenuItem>
              ))}
              {user && user.role !== "ROLE_STUDENT" ? (
                <MenuItem>
                  <a
                    onClick={() => navigate("/admin")}
                    className="mr-8"
                    style={{
                      textDecoration: linkActive(pathname, "admin")
                        ? "underline"
                        : "none",
                    }}
                  >
                    Admin
                  </a>
                </MenuItem>
              ) : (
                ""
              )}
            </MenuGroup>
            <div className="my-2" />
            <MenuDivider />
            <div className="my-2" />
            <MenuGroup>
              {user ? (
                <>
                  <MenuItem>
                    {capitalize(user.name)} {capitalize(user.familyName)}{" "}
                    {user.promotion}
                  </MenuItem>
                  <MenuItem>{user.email}</MenuItem>
                  <div className="my-2" />
                  <MenuDivider />
                  <div className="my-2" />
                  <MenuGroup>
                    <MenuItem
                      onClick={() => {
                        disconnect();
                        navigate("/authentification");
                      }}
                    >
                      <a className={"hover:underline"}>Se déconnecter</a>
                    </MenuItem>
                  </MenuGroup>
                </>
              ) : (
                <MenuItem>
                  <ChakraLink href={"/authentification"}>Connexion</ChakraLink>
                </MenuItem>
              )}
            </MenuGroup>
          </MenuList>
        </Menu>
      </div>
    </div>
  );
};

export default Header;
