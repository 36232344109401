import React from "react";

interface CartNumberInputProps {
  min: number;
  max: number;
  step: number;
  value?: number;
  onValueChange?: (value: number) => boolean;
  disabled?: boolean;
}

const CartNumberInput: React.FC<CartNumberInputProps> = ({
  min,
  max,
  step,
  onValueChange,
  value = 0,
  disabled,
}) => {
  disabled = !!disabled ? disabled : false;

  const increment = () => {
    if (!disabled && value < max) {
      onValueChange && onValueChange(value + step);
    }
  };

  const decrement = () => {
    if (!disabled && value > min) {
      onValueChange && onValueChange(value - step);
    }
  };
  return (
    <div className="flex items-center">
      {/*<button*/}
      {/*  onClick={decrement}*/}
      {/*  className="hover:bg-odm-orange text-black px-2 py-1 rounded-full"*/}
      {/*>*/}
      {/*  -*/}
      {/*</button>*/}
      <input
        type="number"
        value={value}
        readOnly
        className="bg-odm-cream text-black mx-2 px-2 py-1 text-center w-16"
      />
      {/*<button*/}
      {/*  onClick={increment}*/}
      {/*  className="hover:bg-odm-orange text-black px-2 py-1 rounded-full"*/}
      {/*>*/}
      {/*  +*/}
      {/*</button>*/}
    </div>
  );
};

export default CartNumberInput;
