import React, { FC } from 'react';
import clsx from 'clsx';

export const ButtonStyle = {
    dark : "bg-odm-orange text-white",
    light : "bg-white border-odm-orange border-2 text-odm-orange"
}

type ButtonProps = {
    variant? : keyof typeof ButtonStyle
    text: string

} & (
    | (React.ComponentPropsWithoutRef<'button'>)
    )

const Button: FC<ButtonProps> = ({text,variant = "dark",...props}) => {

    return (
        <div className={props.className} style={props.style}>
            <div
                className={clsx(
                    'p-2 flex justify-center items-center h-10 w-max rounded-full active:opacity-80',
                    ButtonStyle[variant])}

            >

                <button
                    className="p-6 transition-transform transform hover:scale-110 border-0 outline-0"
                    onClick={props.onClick}
                >{text}</button>

            </div>
        </div>
    );
}

export default Button;
