import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../../components/UI/Button/Button";
import Layout from "../../../components/UI/Layout/Layout";
import "./PaymentPageWaringDeposit.scss";
import { WarningTwoIcon } from "@chakra-ui/icons";
import { createCheckout } from "../../service/payment.service";
import { useEffect, useState } from "react";
import { Payment } from "../../model/Payment";
import { getPaymentStatus } from "../../service/payment.service";
import { getCart } from "../../service/cart.service";
import { Subscription } from "rxjs";
import { Oval } from "react-loader-spinner";
import getUserSubject from "../../service/user.service";
import { ProductOrder } from "../../model/ProductOrder";

export default function PaymentPageWarningDeposit() {
  const navigate = useNavigate();
  const paymentId = useLocation().pathname.split("/").at(-2);
  const [isLinkDisplayed, setIsLinkDisplayed] = useState(false);
  const [link, setLink] = useState("");
  const [secondPaymentId, setSecondPaymentId] = useState("");
  const [loading, setLoading] = useState(false);
  const [cartProducts, setCartProducts] = useState<ProductOrder[]>([]);
  const [userSubscription, setUserSubscription] = useState<Subscription | null>(
    null
  );

  let intervalId: NodeJS.Timeout;

  useEffect(() => {
    const sub = getUserSubject().subscribe((u) => {
      if (!u) {
        return;
      }
      getCart()
        .then((response) => response.json())
        .then((data) => {
          const cart = data as Payment[];

          setSecondPaymentId(data[1].id);
          setCartProducts(cart[0].productOrders);
        });
    });
    setUserSubscription(sub);

    const handleUnload = () => {
      window.addEventListener("beforeunload", handleUnload);

      return () => {
        clearInterval(intervalId);
        userSubscription?.unsubscribe();
        window.removeEventListener("beforeunload", handleUnload);
      };
    };
  }, []);

  function onBackClick() {
    navigate("/panier");
  }

  function payOnClick() {
    let errorQuantity = false;
    let notEnough = "Impossible de passer la commande pour ces produits. ";
    cartProducts.forEach((elem) => {
      if (elem.quantity > elem.product.disponibility) {
        errorQuantity = true;
        notEnough +=
          "La quantité de " +
          elem.product.name +
          " restante est de " +
          elem.product.disponibility +
          " et votre commande en comporte " +
          elem.quantity;
      }
      notEnough += " Veuillez diminuer la quantité des produits en surplus";
    });
    if (errorQuantity) {
      alert(notEnough);
      return;
    }
    setLoading(true);
    createCheckout(paymentId!)
      .then((response) => response.json())

      .then((data) => {
        const payment = data as Payment;
        window.open(payment.helloassoCheckoutUrl);
        setLink(payment.helloassoCheckoutUrl);
        setIsLinkDisplayed(true);
      })
      .catch((error) => {setLoading(false)});

    intervalId = setInterval(() => {
      getPaymentStatus(paymentId!)
        .then((response) => response.json())
        .then((data) => {
          const payment = data as Payment;
          if (payment.paymentStatus == "PAID") {
            navigate("/Payment/" + secondPaymentId);
            clearInterval(intervalId);
          }
        })
        .catch((error) => console.log(error));
    }, 5000);
  }

  return (
    <div className={"min-h-full bg-odm-cream pb-8"}>
      <Layout>
        {loading ? (
          <div className={"flex items-center justify-center mt-8 flex-col"}>
            <Oval
              height="160"
              width="160"
              color="#fe9200"
              ariaLabel="oval-loading"
              wrapperStyle={{}}
              wrapperClass=""
              secondaryColor="#fe9200"
            />
            <a href={link} target="_blank" className={"underline mt-8"}>
              Lien vers le payement
            </a>
          </div>
        ) : (
          <div>
            <div
              className={
                "flex flex-col items-center justify-center text-center gap-5 m-4"
              }
            >
              <WarningTwoIcon color="red" boxSize={50}/>
              <p className={"text-xl"}>
                Le paiement va s'effectuer en 2 PARTIES, une première partie
                pour payer la CONSIGNE des bouteilles <br />
                et une deuxième partie pour payer le produit. Si vous nous
                ramenez les bouteilles vides <br />
                et lavées, la consigne vous sera REMBOURSEE !
              </p>
              <p>Lors du paiement, n'oubliez pas de retirer ou d'ajuster la cotisation
              de HelloAsso !</p>
              <div className={"flex flex-row gap-4 mt-8"}>
                <Button text="Annuler" onClick={() => onBackClick()} />
                <Button text="Payer la consigne" onClick={() => payOnClick()} />
              </div>
            </div>
          </div>
        )}
      </Layout>
    </div>
  );
}
