import NumberInput from "../UI/NumberInput/NumberInput";
import { CloseButton, Divider } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { getProductBaseImage } from "../../app/service/product.service";
import {
  getCart,
  getCartSubject,
  removeFromCart,
  updateCartProductQuantity,
} from "../../app/service/cart.service";
import { useNavigate } from "react-router-dom";
import { ProductOrder } from "../../app/model/ProductOrder";
import getUserSubject from "../../app/service/user.service";
import { Payment } from "../../app/model/Payment";
import { capitalize } from "../../app/helper/helper";
import clsx from "clsx";
import { Subscription } from "rxjs";

const CartItem = ({
  disableQuantityChange,
  productOrder,
}: {
  disableQuantityChange?: boolean;
  productOrder: ProductOrder;
}) => {
  const [quantityNumberInput, setQuantityNumberInput] = useState(
    productOrder.quantity
  );
  const [userSubscription, setUserSubscription] = useState<Subscription | null>(
    null
  );

  const getCurrentCart = () => {
    getCart()
      .then((response) => response.json())
      .then((data) => {
        const payments = data as Payment[];
        getCartSubject().next(payments);
      });
  };

  useEffect(() => {
    const handleUnload = () => {
      if (quantityNumberInput !== productOrder.quantity) {
        updateCartProductQuantity(productOrder.id, quantityNumberInput!);
      }
    };

    window.addEventListener("beforeunload", handleUnload);

    return () => {
      if (quantityNumberInput !== productOrder.quantity) {
        updateCartProductQuantity(productOrder.id, quantityNumberInput!);
      }
      userSubscription?.unsubscribe();
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    const sub = getUserSubject().subscribe((u) => {
      if (!u) {
        return;
      }
      getCurrentCart();
    });

    setUserSubscription(sub);
  }, []);

  const valueChange = (value?: number): boolean => {
    // let total = 0;
    // if (!cart) return false;
    // cart[0].productOrders?.map((p) => {
    //   total += p.quantity;
    // });
    // if (value! + total > 6 && value! !== quantity) {
    //   alert(
    //     "Pour des raisons de stocks et d'équité (et pour ton foie),\n La quantité est limité à six bieres par commande"
    //   );
    //   setQuantityNumberInput(Number(quantity));
    //   return false;
    // }
    // setQuantityNumberInput(Number(value));
    return true;
  };

  const removeItemFromCart = () => {
    if (disableQuantityChange) return;
    removeFromCart(productOrder.id).then((response) => {
      if (response.status === 200) {
        getCurrentCart();
      }
    });
  };

  const handleProductOnClick = () => {
    navigate(`/boutique/product-page/${productOrder.product.id}`);
  };

  return (
    <div className={"flex justify-center"}>
      <div
        className={clsx(
          "bg-opacity-40 bg-grey border rounded-xl text-xl w-4/5 relative",
          "px-16 py-4 my-2 ml-2 mr-2 justify-between",
          "lg:flex"
        )}
      >
        <div
          className={clsx(
            "flex w-full flex-col items-center",
            "lg:flex-row lg:justify-around"
          )}
        >
          <div
            className={
              "w-36 h-auto lg:w-48 max-w-xs aspect-square rounded-full mb-4 lg:mb-0 lg:mr-4 cursor-pointer"
            }
            style={{
              backgroundImage: `url(${getProductBaseImage(
                productOrder.product
              )})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
            onClick={() => handleProductOnClick()}
          ></div>
          <h2 className="text-2xl" onClick={() => handleProductOnClick()}>
            {capitalize(productOrder.product.name)}
          </h2>
          <NumberInput
            min={1}
            max={3}
            step={1}
            value={Number(productOrder.quantity)}
            disabled={disableQuantityChange}
          />
          <p>
            {(productOrder.product.price + productOrder.product.depositPrice) *
              productOrder.quantity}
            €
          </p>
        </div>
        {disableQuantityChange ? (
          ""
        ) : (
          <CloseButton
            className={clsx("absolute top-4 right-4")}
            onClick={() => {
              removeItemFromCart();
            }}
          />
        )}
      </div>
    </div>
  );
};

export default CartItem;
