import { Modal } from "@chakra-ui/react";
import { ApiService } from "./apiService";

export function createCheckout(payementId: string) {
  return ApiService.get("/payments/checkout/" + payementId);
}

export function getPaymentStatus(payementId: string) {
  return ApiService.get("/payments/status/" + payementId);
}

export function getUserPayments() {
  return ApiService.get("/payments/user_payments");
}


export function getAbortedPayements() {
  return ApiService.get("/payments/aborted");
}
