import { useState } from "react";
import Button from "../../../components/UI/Button/Button";
import TextField from "../../../components/UI/TextField/TextField";
import Radio from "../../../components/UI/Radio/Radio";
import Selector from "../../../components/UI/Select/Selector";
import CartNumberInput from "../../../components/UI/NumberInput/NumberInput";
import FileUploader from "../../../components/UI/FileUploader/FileUploader";

function Components() {
  const [text, setText] = useState("");
  const [radioValue, setRadioValue] = useState("");
  const [selectValue, setSelectValue] = useState("");

  return (
    <div>
      <Button
        text={"Button"}
        variant={"dark"}
        onClick={() => {
          setText(text + "a");
          setRadioValue("option2");
          console.log(selectValue);
        }}
      ></Button>

      <TextField
        placeholder={"Name"}
        value={text}
        onValueChange={(value: string) => {
          setText(value);
        }}
        type={"password"}
      ></TextField>

      <Radio
        value={radioValue}
        values={[
          { value: "option1", label: "Option 1" },
          { value: "option2", label: "Option 2" },
          { value: "option3", label: "Option 3" },
        ]}
        onValueChange={(value: string) => {
          setRadioValue(value);
        }}
      />

      <Selector
        values={[
          { value: "option1", label: "Option 1" },
          { value: "option2", label: "Option 2" },
          { value: "option3", label: "Option 3" },
        ]}
        onValueChange={(value: string) => {
          setSelectValue(value);
        }}
        value={selectValue}
      />
      <FileUploader
        onValueChange={(files: File[]) => console.log(files)}
        validExtensions={["JPG", "PNG", "JPEG", "TXT"]}
      />
      <CartNumberInput value={1} min={1} max={10} step={1} />
    </div>
  );
}

export default Components;
