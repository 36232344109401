import "./PaymentSuccess.scss";
import Layout from "../../../components/UI/Layout/Layout";

export default function PaymentSuccess() {
  return (
    <>
      <div className="w-full bg-odm-cream">
        <Layout>
          <div className="items-center text-center justify-center gap-y-20">
            <h1 className="text-black p-5 ml-10 text-4xl">
              Paiement réussi vous pouvez fermer cet onglet et revenir à
              l'onglet précédent
            </h1>
          </div>
        </Layout>
      </div>
    </>
  );
}
