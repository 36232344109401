import { FC } from "react";
import { useNavigate } from "react-router-dom";

type HomeProductProps = {
  imageSource: string;
  productName: string;
  price?: number;
  id: number;
};

const HomeProduct: FC<HomeProductProps> = ({
  imageSource,
  productName,
  price,
  id,
}) => {
  const navigate = useNavigate();

  const handleClick = (id: number) => {
    navigate(`/boutique/product-page/${id}`);
  };

  return (
    <div
      className={
        "flex justify-center items-center flex-col w-full mb-16 lg:mb-0"
      }
      onClick={() => handleClick(id)}
    >
      <div
        className={
          "lg:w-56 w-72 aspect-square rounded-full mb-4 cursor-pointer"
        }
        style={{
          backgroundImage: `url(${imageSource})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      ></div>
      <p className={"text-2xl text-center cursor-pointer"}>{productName}</p>
      <p className={"text-xl text-center cursor-pointer"}>{price}€</p>
    </div>
  );
};

export default HomeProduct;
