import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Home from "./app/site/Home/Home";
import Components from "./app/site/Components/Components";
import Authentification from "./app/site/Authentification/Authentification";
import getUserSubject, {
  getConnectionAttempted,
  getCurrentUser,
  getTokenFromRefreshToken
} from "./app/service/user.service";
import { TokenSet } from "./app/model/TokenSet";
import { refreshTokenSubject, tokenSubject } from "./app/service/apiService";
import { User } from "./app/model/User";
import Shop from "./app/site/Shop/Shop";
import ProductPage from "./app/site/Product-page/Product-page";
import Cart from "./app/site/Cart/Cart";
import Contact from "./app/site/Contact/Contact";
import Admin from "./app/site/Admin/Admin";
import ProductAdminDetail from "./app/site/Admin/ProductAdminDetail/ProductAdminDetail";
import getProductsSubject, { getProducts } from "./app/service/product.service";
import { Product } from "./app/model/Product";
import PayementPageWarningDeposit from "./app/site/Payment/PaymentPageWaringDeposit";
import { PaymentPageNoDeposit } from "./app/site/Payment/PaymentPageNoDeposit";
import PaymentSuccess from "./app/site/PaymentSuccess/PaymentSuccess";

export default function App() {
  useEffect(() => {
    getProducts()
      .then((response) => response.json())
      .then((data) => {
        const products = data as Product[];
        getProductsSubject().next(products);
      })
      .catch((error) => error);

    const refreshToken = localStorage.getItem("refresh-token");
    if (!refreshToken) {
      getConnectionAttempted().next(true);
      return;
    }
    getTokenFromRefreshToken(refreshToken)
      .then((response) => response.json())
      .then((data) => {
        const tokenSet = data as TokenSet;
        tokenSubject.next(tokenSet.token);
        refreshTokenSubject.next(tokenSet.refreshToken);
        localStorage.setItem("refresh-token", tokenSet.refreshToken);
      })
      .then(() => {
        return getCurrentUser();
      })
      .then((response) => response.json())
      .then((data) => {
        const user = data as User;
        getUserSubject().next(user);
        getConnectionAttempted().next(true);
      })
      .catch((error) => {
        getConnectionAttempted().next(true);
      });
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to="/authentification" />} />
        <Route path="/home" element={<Home />} />
        <Route path="/authentification" element={<Authentification />} />
        <Route path="/components" element={<Components />} />
        <Route path="/admin" element={<Admin />} />
        <Route path={"admin/product/:id"} element={<ProductAdminDetail />} />
        <Route path="/boutique" element={<Shop />} />
        <Route
          path="/boutique/product-page/:productId"
          element={<ProductPage />}
        />
        <Route
          path="/Payment/:paymentId/Deposit"
          element={<PayementPageWarningDeposit />}
        />
        <Route path="/Payment/:paymentId" element={<PaymentPageNoDeposit />} />
        <Route path="/panier" element={<Cart />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/success" element={<PaymentSuccess />} />
        <Route
          path="*"
          element={
            <div>
              <h2>404 Page not found</h2>
            </div>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
