import Layout from "../../../components/UI/Layout/Layout";
import CartItem from "../../../components/CartItem/CartItem";
import Button from "../../../components/UI/Button/Button";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import getUserSubject from "../../service/user.service";
import { Payment } from "../../model/Payment";
import { ProductOrder } from "../../model/ProductOrder";
import { getCart, getCartSubject } from "../../service/cart.service";
import { getAbortedPayements } from "../../service/payment.service";
import { Subscription } from "rxjs";

function Cart() {
  const [cartProducts, setCartProducts] = useState<ProductOrder[]>([]);
  const [payementId, setPaymentId] = useState(0);
  const navigate = useNavigate();
  const [userSubscription, setUserSubscription] = useState<Subscription | null>(
    null
  );
  const [cartSubscription, setCartSubscription] = useState<Subscription | null>(
    null
  );

  const [abortedPayments, setAbortedPayments] = useState<Payment[]>([]);

  useEffect(() => {
    const cartSub = getCartSubject().subscribe((cart) => {
      if (!cart) {
        return;
      }
      setCartProducts(cart[0].productOrders);
      setPaymentId(cart[0].id);
    });
    setCartSubscription(cartSub);

    const sub = getUserSubject().subscribe((u) => {
      if (!u) {
        return;
      }

      getCart()
        .then((response) => response.json())
        .then((data) => {
          const payments = data as Payment[];
          getCartSubject().next(payments);
          setCartProducts(payments[0].productOrders);
        })
        .catch((error) => console.log(error));

      getAbortedPayements().then((response) => {
        response.json().then((data) => {
          const payments = data as Payment[];
          setAbortedPayments(payments);
        });
      });
    });
    setUserSubscription(sub);
  }, []);

  useEffect(() => {
    const handleUnload = () => {
      window.addEventListener("beforeunload", handleUnload);

      return () => {
        cartSubscription?.unsubscribe();
        userSubscription?.unsubscribe();
        window.removeEventListener("beforeunload", handleUnload);
      };
    };
  });

  function buttonOnClick() {
    if (payementId !== 0) {
      navigate("/Payment/" + payementId + "/Deposit");
    }
  }

  function finishPayment() {
    if (abortedPayments.length) {
      navigate("/Payment/" + abortedPayments[0].id);
    }
  }

  if (!cartProducts?.length && !abortedPayments?.length) {
    return (
      <div className={" w-full bg-odm-cream"}>
        <Layout>
          <div className="items-center justify-center flex flex-col">
            <h2 className="my-8 text-3xl">Votre panier est vide</h2>
            <Button
              text="Poursuivre vos achats"
              onClick={() => {
                navigate("/boutique");
              }}
            />
          </div>
        </Layout>
      </div>
    );
  }

  return (
    <div className={" w-full bg-odm-cream"}>
      <Layout>
        <h1 className="text-odm-orange p-5 ml-10 text-4xl">Panier</h1>
        {cartProducts
          .sort((a, b) => a.product.name.localeCompare(b.product.name))
          .map((product) => {
            return <CartItem productOrder={product} />;
          })}
        {!!abortedPayments && !!abortedPayments.length ? (
          <>
            <div className="flex items-center justify-center mt-10 mb-2">
              <h2 className="text-4xl">Payment abandonnés :</h2>
            </div>
            {abortedPayments.map((product) => {
              return product.productOrders.map((productOrder) => {
                return (
                  <div className="mb-8">
                    <CartItem
                      disableQuantityChange={true}
                      productOrder={productOrder}
                    />
                  </div>
                );
              });
            })}
          </>
        ) : null}

        <div className="flex justify-center mb-8 gap-5">
          {!!abortedPayments && !!abortedPayments.length && (
            <Button
              text="Finaliser les payements en cours"
              onClick={() => finishPayment()}
            />
          )}
          {!!cartProducts && !!cartProducts.length && (
            <Button text="Valider le panier" onClick={() => buttonOnClick()} />
          )}
        </div>
      </Layout>
    </div>
  );
}

export default Cart;
