import { FC } from "react";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { capitalize } from "../../app/helper/helper";

type ShopProduct = {
  imageSource: string;
  productName: string;
  productId: number;
  price: number;
  productDescription?: string;
  disponibility?: number;
  isVisible?: boolean;
};

const ShopProduct: FC<ShopProduct> = ({
  imageSource,
  productName,
  price,
  productDescription,
  productId,
  disponibility,
  isVisible,
}) => {
  const navigate = useNavigate();
  const handleLinkClick = () => {
    navigate(`/boutique/product-page/${productId}`);
  };

  return (
    ((disponibility ?? 0) > 0 && isVisible && (
      <div className={"flex flex-col items-center w-full"}>
        <div
          className={clsx(
            "w-1/2 rounded-xl p-4 my-4 cursor-pointer",
            "lg:w-4/5 lg:flex lg:items-center lg:justify-around",
            "hover: hover:border-2"
          )}
          onClick={handleLinkClick}
        >
          <div className="flex justify-center items-center">
            <div
              className={clsx(
                "w-72 lg:w-96 aspect-square rounded-full cursor-pointer"
              )}
              style={{
                backgroundImage: `url(${imageSource})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
              onClick={handleLinkClick}
            ></div>
          </div>

          <div
            className="flex w-full flex-col justify-center items-center cursor-pointer text-center"
            onClick={handleLinkClick}
          >
            <div className="text-center">
              <div>
                <h2 className="text-3xl font-bold mt-4 lg:mt-0">
                  {capitalize(productName)}
                </h2>
                <p className="text-gray-600">{price}€</p>
              </div>
            </div>

          </div>
        </div>
      </div>
    )) || <></>
  );
};

export default ShopProduct;
