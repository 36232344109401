import { useEffect, useState } from "react";
import getUserSubject from "../../service/user.service";
import Layout from "../../../components/UI/Layout/Layout";
import clsx from "clsx";
import "./Home.scss";
import Button from "../../../components/UI/Button/Button";
import HomeProduct from "../../../components/HomeProduct/HomeProduct";
import { Product } from "../../model/Product";
import getProductsSubject, {
  getProductBaseImage,
  getProducts,
} from "../../service/product.service";
import { capitalize } from "../../helper/helper";
import { Subscription } from "rxjs";
import { cartSubject } from "../../service/cart.service";
import { useNavigate } from "react-router-dom";

function Home() {
  const [products, setProducts] = useState<Product[] | null>(
    getProductsSubject().value
  );
  const [userSubscription , setUserSubscription] = useState<Subscription | null>(null)

  const navigate = useNavigate();

  const handleKnowMoreClick = () => {
    window.open(
      "https://www.instagram.com/ordredumalt?igsh=MTlpc2M4bHZlcXpsbg%3D%3D&utm_source=qr "
    );
  };

  useEffect(() => {
    const handleUnload = () => {
      window.addEventListener("beforeunload", handleUnload);

      return () => {
        cartSubject.unsubscribe();
        userSubscription?.unsubscribe();
        window.removeEventListener("beforeunload", handleUnload);
      };
    };
  });

  useEffect(() => {
    getProductsSubject().subscribe((products) => setProducts(products));

    const sub = getUserSubject().subscribe((u) =>
      getProducts()
        .then((response) => response.json())
        .then((data) => {
          const products = data as Product[];
          setProducts(products);
        })
        .catch((error) => console.log(error))
    );
    setUserSubscription(sub)
  }, []);

  return (
    <div className={"min-h-full bg-odm-cream pb-8"}>
      <div
        className={clsx("background-image", "w-full h-[34rem] absolute")}
      ></div>
      <div className={"relative"}>
        <Layout>
          <div className={"flex justify-center items-center h-64 flex-col"}>
            <h1 className={"text-5xl"}>Ordre Du Malt</h1>
            <h2 className={"mt-2 text-2xl text-center"}>
              Association de brassage et dégustation de bière
            </h2>
            <h3 className={"text-2xl"}>ISEP</h3>
          </div>
          <div className={"flex justify-center items-center h-8 flex-col"}>
            <Button
              text={"En savoir plus"}
              onClick={() => handleKnowMoreClick()}
            ></Button>
          </div>

          <div className={"flex justify-center items-center flex-col mt-56"}>
            <h1 className={"font-bold text-4xl mb-24 px-4 text-center"}>
              Nos Produits Dégustation
            </h1>
            <div className={"lg:flex justify-between w-full pl-16 pr-16"}>
              {products?.map((p) => (
                <HomeProduct
                  imageSource={getProductBaseImage(p)}
                  productName={capitalize(p.name)}
                  price={p.price + p.depositPrice}
                  id={p.id}
                />
              ))}
            </div>
          </div>
          <div className={"flex justify-center items-center flex-col mt-24"}>
            <h1 className={"text-4xl mb-16"}>Notre jeu du WEI</h1>
            <Button text={"Beer Clicker"} onClick={() => navigate("/wei")}></Button>
          </div>
        </Layout>
      </div>
    </div>
  );
}

export default Home;
