import { FC, useState } from "react";
import { Options } from "../../../app/model/Options";
import clsx from "clsx";
import "./Select.scss";

type SelectProps = {
  values: Options[];
  onValueChange: Function;
  value: string;
};

const Selector: FC<SelectProps> = ({
  values,
  onValueChange,
  value,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <div
      className={clsx(
        "flex items-center h-8 rounded bg-grey",
        `${
          isFocused
            ? "border-2 outline-1 border-odm-orange"
            : "border-2 outline-1 border-grey"
        }`
      )}
      onFocus={handleFocus}
      onBlur={handleBlur}
    >
      <select
        className={clsx(
          "react-select__option",
          "outline-0 h-full w-full rounded bg-grey"
        )}
        onChange={(event) => onValueChange(event.target.value)}
        value={value}
      >
        {values.map((element) => (
          <option className={clsx("bg-grey")} value={element.value}>
            {element.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Selector;
