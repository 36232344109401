import TextField from "../../../components/UI/TextField/TextField";
import Button from "../../../components/UI/Button/Button";
import {useEffect, useState} from "react";
import {Controller, set, SubmitHandler, useForm} from "react-hook-form";
import {AuthentificationForm} from "../../forms/AuthentificationForm";
import {Oval} from "react-loader-spinner";
import {refreshTokenSubject, tokenSubject} from "../../service/apiService";
import getUserSubject, {connect, getCurrentUser} from "../../service/user.service";
import {User} from "../../model/User";
import {useNavigate} from "react-router-dom";
import { cartSubject } from "../../service/cart.service";
import { Subscription } from "rxjs";


function Authentification(){
    const {
        handleSubmit,
        control,
        formState:{errors}
        } =
        useForm<AuthentificationForm>({
            defaultValues:{
                id:"",
                password:""
            }
        })

    const navigate =  useNavigate();
    const [authSuccess, setAuthSuccess] = useState(true);
    const [loading, setLoading] = useState(false);
    const [userSubscription , setUserSubscription] = useState<Subscription | null>(null)

    useEffect(() => {
        const sub = getUserSubject().subscribe(u => {
            if(u){
                navigate("/home")
            }
        })
        setUserSubscription(sub);
    }, []);

    useEffect(() => {
        const handleUnload = () => {
            window.addEventListener("beforeunload", handleUnload);

            return () => {
                cartSubject.unsubscribe();

                userSubscription?.unsubscribe();

                window.removeEventListener("beforeunload", handleUnload);
            };
        };
    });

    const onSubmit: SubmitHandler<AuthentificationForm> = (data) => {
        setAuthSuccess(true)
        setLoading(true)

        return connect({username:data.id,password:data.password})
            .then(result=> {
                setLoading(false)
                if(result.status!=200){
                    setAuthSuccess(false)
                    return;
                }
                return result.json()
            }).then(dat => {
                if(!dat){
                    throw new Error("Connection invalid")
                }
                tokenSubject.next(dat.token)
                refreshTokenSubject.next(dat.refreshToken)
                localStorage.setItem("refresh-token",dat.refreshToken)
            }).then( () =>{
                getCurrentUser()
                    .then(response => response.json())
                    .then(dat => {
                        const user = dat as User
                        getUserSubject().next(user)
                    })}
            ).then(() =>
               navigate("/home")
            ).catch((e) => console.log(e))

    }

    return (
        <div className={"flex justify-center items-center h-screen w-screen"}>

            <form onSubmit={handleSubmit(onSubmit)}
                className={"flex justify-center items-center flex-col h-fit bg-grey p-8 rounded-xl  w-80 drop-shadow-xl"}
            >
                <h2 className={"m-2 font-bold"}>
                    Connexion
                </h2>
                {loading?

                <div className={"mt-8"}>
                    <Oval

                        height="80"
                        width="80"
                        color="#fe9200"
                        ariaLabel="oval-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        secondaryColor='#fe9200'
                    />
                </div>
                :
                <div className={"flex justify-center items-center flex-col "}>

                    {authSuccess?
                        <></>
                        :
                        <h3 className={"text-error mt-4 mb-4"}>
                           Identifiant ou mot de passe invalide
                        </h3>


                    }

                    <div className={"m-2"}>
                        <Controller render={({field})=>

                            <TextField
                                {...field}
                                type={"text"}
                                placeholder={"Identifiant Isep"}
                                isInvalid={errors.id}
                            />

                        }
                            name={"id"}
                            rules={{required: "Ce champ ne doit pas être vide"}}
                            control={control}
                        />

                    </div>
                    <div className={"m-2"}>
                        <Controller
                            render={({field}) =>
                                <TextField
                                    {...field}
                                    type={"password"}
                                    placeholder={"Mot de passe"}
                                    isInvalid={errors.password}

                                />}
                                    name={"password"}
                                    rules={{required: "Ce champ ne doit pas être vide"}}
                                    control={control}/>
                    </div>
                    <div className={"m-2"}>
                        <Button
                            text={"Connect"}
                            type={"submit"}
                        />
                    </div>
                </div>
            }
            </form>
        </div>
    )
}

export default Authentification;

