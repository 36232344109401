import { useEffect, useState } from "react";
import { User } from "../../model/User";
import getUserSubject, {
  getConnectionAttempted,
} from "../../service/user.service";
import { useNavigate } from "react-router-dom";
import Layout from "../../../components/UI/Layout/Layout";
import { ApiService } from "../../service/apiService";
import { Payment } from "../../model/Payment";
import Button from "../../../components/UI/Button/Button";
import { Product } from "../../model/Product";
import getProductsSubject, { getProducts } from "../../service/product.service";
import ProductAdmin from "./ProductAdmin/ProductAdmin";
import { Subscription } from "rxjs";
import { cartSubject } from "../../service/cart.service";

export default function Admin() {
  const [user, setUser] = useState<User | null>(getUserSubject().value);

  const [totalMoney, setTotalMoney] = useState(0);

  const [products, setProducts] = useState<Product[] | null>(
    getProductsSubject().value
  );
  const [userSubscription , setUserSubscription] = useState<Subscription | null>(null)


  const navigate = useNavigate();

  useEffect(() => {
    getProductsSubject().subscribe((p) => setProducts(p));

    const sub = getUserSubject().subscribe((u) => {
      if(!getConnectionAttempted().value){
        return;
      }
      if(!u){
        return;
      }

      if (u.role !== "ROLE_ADMIN" && u.role !== "ROLE_SUPER_ADMIN") {
        console.log("Je redirige !")
        navigate("/home");
        return;
      }
      setUser(u);

      ApiService.get("/payments/paid")
        .then((response) => response.json())
        .then((data) => {
          const payments = data as Payment[];
          let valueEarned = 0;
          payments.map((payment) => (valueEarned += payment.price));
          setTotalMoney(valueEarned);
        });

      getProducts()
        .then((response) => response.json())
        .then((data) => {
          const products = data as Product[];
          //getProductsSubject().next(products);
        })
        .catch((error) => console.log(error));
    });
    setUserSubscription(sub);

  }, []);

  useEffect(() => {
    const handleUnload = () => {
      window.addEventListener("beforeunload", handleUnload);

      return () => {
        userSubscription?.unsubscribe();

        window.removeEventListener("beforeunload", handleUnload);
      };
    };
  });

  return (
    <div className={"min-h-full bg-odm-cream pb-8"}>
      <Layout>
        <div className={"flex justify-center items-center flex-col"}>
          <h1 className={"mt-12 text-4xl"}>Revenus générés par nos ventes :</h1>
          <h3 className={"mt-8 text-4xl"}>{totalMoney}€</h3>
        </div>
        <div className={"flex flex-col mt-16 ml-12"}>
          <h3 className={"text-2xl"}>Produits</h3>
          {products?.map((product) => (
            <ProductAdmin product={product} />
          ))}
        </div>
      </Layout>
    </div>
  );
}
