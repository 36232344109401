import React, { useEffect, useState, useRef } from 'react';
import Button from "../../../../components/UI/Button/Button";


interface RandomButtonProps {
  setStillHereVisible: Function
}

const RandomButton : React.FC<RandomButtonProps>= ({setStillHereVisible}) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const containerRef = useRef<HTMLDivElement>(null);// Reference to the div

  // Function to randomize button position within the container div
  const randomizeButtonPosition = () => {
    if (containerRef.current) {
      const container = containerRef.current;
      const x = container.getBoundingClientRect().left
      const y = container.getBoundingClientRect().top + window.scrollY;

      const containerWidth = container.offsetWidth;
      const containerHeight = container.offsetHeight;

      const buttonWidth = 152; // Assume a fixed button width
      const buttonHeight = 40; // Assume a fixed button height

      // Randomize X and Y within container bounds
      const randomX = x + Math.floor(Math.random() * (containerWidth - buttonWidth));
      const randomY = y + Math.floor(Math.random() * (containerHeight - buttonHeight));


      setPosition({ x: randomX, y: randomY });
    }
  };

  const buttonClick = () => {
    randomizeButtonPosition()
    setStillHereVisible(false)
  }

  useEffect(() => {
    randomizeButtonPosition();
  }, []);

  return (
    <div
      ref={containerRef}
      className="w-full flex justify-center flex-col items-center min-w-96 min-h-96"
    >
      <Button text={"Toujours là ?"} onClick={buttonClick}
              className="absolute w-28"
              style={{
                left: `${position.x}px`,
                top: `${position.y}px`,
              }}
      ></Button>
    </div>
  );
};

export default RandomButton;
