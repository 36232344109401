import React, { FC } from "react";
import { Options } from "../../../app/model/Options";
import clsx from "clsx";

type RadioProps = {
  values: Options[];
  onValueChange: Function;
  value: string;
} & React.ComponentPropsWithoutRef<"input">;

const Radio: FC<RadioProps> = ({ values, onValueChange, value, ...props }) => {
  return (
    <div>
      {values.map((element) => {
        return (
          <div
            className={"flex items-center cursor-pointer"}
            onClick={() => {
              onValueChange(element.value);
            }}
          >
            <span
              className={clsx(
                "flex justify-center items-center h-4 w-4 mr-2 rounded-full cursor-pointer border-2 border-odm-orange"
              )}
            >
              <span
                className={clsx(
                  "h-2 w-2 rounded-full cursor-pointer ",
                  element.value === value ? "bg-odm-orange" : ""
                )}
              />
            </span>
            <input
              className={"hidden"}
              type={"radio"}
              checked={element.value === value}
              onChange={(event) => {
                value = event.target.value;
              }}
              {...props}
            />
            <label className={"cursor-pointer"}>{element.label}</label>
          </div>
        );
      })}
    </div>
  );
};

export default Radio;
