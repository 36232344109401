import { BehaviorSubject } from "rxjs";
import { Product } from "../model/Product";
import { ApiService } from "./apiService";
import { getImageByName } from "./image.service";

const productsSubject = new BehaviorSubject<Product[] | null>(null);

export function getProducts() {
  return ApiService.get("/public/products");
}

export default function getProductsSubject() {
  return productsSubject;
}

export const getProductBaseImage = (product: Product) => {
  if (product.images.length > 0 && product.images) {
    const image = product.images.at(0);
    if (image && image.name) {
      return getImageByName(image.name);
    }
  }
  return "/ressources/images/product_example.jpg";
};
