import { AuthInfo } from "../model/AuthInfo";
import { ApiService } from "./apiService";
import {BehaviorSubject, retry} from "rxjs";
import { User } from "../model/User";
import exp from "constants";

const userSubject = new BehaviorSubject<User | null>(null);

const connectionAttempted = new BehaviorSubject<boolean>(false)

export function connect(authInfo: AuthInfo) {
  return ApiService.post("/auth", authInfo);
}

export function getTokenFromRefreshToken(refreshToken: string) {
  return ApiService.post("/auth/refresh", { refreshToken: refreshToken });
}

export function getCurrentUser() {
  return ApiService.get("/students/me");
}

export function disconnect() {
  localStorage.removeItem("refresh-token");
  userSubject.next(null);

}

export default function getUserSubject() {
  return userSubject;
}

export function getConnectionAttempted(){
  return connectionAttempted;
}