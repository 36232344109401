import clsx from "clsx";
import Layout from "../../../components/UI/Layout/Layout";
import Button from "../../../components/UI/Button/Button";
import Selector from "../../../components/UI/Select/Selector";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import getUserSubject from "../../service/user.service";
import { User } from "../../model/User";
import { Product } from "../../model/Product";
import getProductsSubject, {
  getProductBaseImage,
} from "../../service/product.service";
import {
  addToCart,
  getCart,
  updateCartProductQuantity,
} from "../../service/cart.service";
import { Payment } from "../../model/Payment";
import { ProductOrder } from "../../model/ProductOrder";
import { capitalize } from "../../helper/helper";
import {
  getAbortedPayements,
  getUserPayments,
} from "../../service/payment.service";
import { Subscription } from "rxjs";
import { Oval } from "react-loader-spinner";

function ProductPage() {
  const [selectedValue, setSelectedValue] = useState("1");
  const [product, setProduct] = useState<Product | null>(null);
  const [cartProducts, setCartProducts] = useState<ProductOrder[] | null>(null);
  const [loaded, setLoaded] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const [cartSuccess, setCartSuccess] = useState(false);
  const [aborted, setAborted] = useState<Payment[]>([]);
  const [userSubscription, setUserSubscription] = useState<Subscription | null>(
    null
  );
  const [addingItem, setAddingItem] = useState(false);
  const handleValueChange = (value: string) => {
    setSelectedValue(value);
  };

  const productId = useLocation().pathname.split("/").at(-1);

  const updateCartValue = () => {
    return getCart()
      .then((response) => response.json())
      .then((data) => {
        const payments = data as Payment[];
        setCartProducts(payments[0].productOrders);
      })
      .catch((error) => error);
  };

  useEffect(() => {
    const handleUnload = () => {
      window.addEventListener("beforeunload", handleUnload);

      return () => {
        userSubscription?.unsubscribe();
        window.removeEventListener("beforeunload", handleUnload);
      };
    };
  });

  useEffect(() => {
    getProductsSubject().subscribe((products) => {
      if (!products) {
        return;
      }

      const foundProduct = products.find((p) => p.id === Number(productId));
      if (!foundProduct) {
        throw new Error("Unable to find a product with this ID");
      }

      setProduct(foundProduct);
    });

    const sub = getUserSubject().subscribe((u) => {
      if (!u) {
        return;
      }
      setUser(u);

      getAbortedPayements()
        .then((response) => response.json())
        .then((data) => {
          const aborted = data as Payment[];
          setAborted(aborted);
        });

      setLoaded(true);
      updateCartValue();
    });
    setUserSubscription(sub);
  }, []);

  const handleClick = () => {
    setAddingItem(true);

    getUserPayments()
      .then((result) => result.json())
      .then((data) => {
        const payments = data as Payment[];
        getCart()
          .then((result) => result.json())
          .then((data) => {
            const cart = data as Payment[];
            const po = cart[0].productOrders;

            setAddingItem(false);
            let total = 0;
            if (aborted.length > 0) {
              alert(
                "Vous avez des paiements non terminés ! Rendez vous dans votre panier pour les finaliser avant d'ajouter de nouveaux produits"
              );
              return;
            }

            payments.forEach((pay) => {
              if (!pay.isDeposit) {
                pay.productOrders.forEach((po) => (total += po.quantity));
              }
            });

            const existingProductOrderForPageProduct = po.find(
              (order) => order.product.id === Number(productId)
            );
            console.log(
              "Existing number :",
              existingProductOrderForPageProduct?.quantity
            );
            console.log("Adding :", selectedValue);
            
            if (!user) {
              throw new Error("Seems like user is deconnected");
            }

            if(true){ //Replace by role !== ROLE_STUDENT to put limit back
              if(existingProductOrderForPageProduct && loaded){
                updateCartProductQuantity(
                  existingProductOrderForPageProduct.id,
                  existingProductOrderForPageProduct.quantity +
                  Number(selectedValue)
                ).then(result => console.log(result.json))
                .then(data => console.log(data));
                updateCartProductQuantity(
                  existingProductOrderForPageProduct.id,
                  existingProductOrderForPageProduct.quantity +
                  Number(selectedValue)
                ).then(() => updateCartValue());
                setCartSuccess(true);
                return;
              }
              addToCart(
                Number(productId),
                user.studentCode,
                Number(selectedValue),
                "BOTTLE"
              );
              setCartSuccess(true);
              return;
            }
            //remove comments to put limit back

            // if (
            //   existingProductOrderForPageProduct &&
            //   existingProductOrderForPageProduct.quantity +
            //   Number(selectedValue) <= 3 &&
            //   loaded && Number(selectedValue) + total <= 6
            // ) {
            //   updateCartProductQuantity(
            //     existingProductOrderForPageProduct.id,
            //     existingProductOrderForPageProduct.quantity +
            //     Number(selectedValue)
            //   ).then(() => updateCartValue());
            //   setCartSuccess(true);
            //   return;
            // }

            // if (
            //   Number(selectedValue) + total <= 6 &&
            //   !existingProductOrderForPageProduct
            // ) {
            //   if (!user) {
            //     throw new Error("Seems like user is deconnected");
            //   }
            
            //   addToCart(
            //     Number(productId),
            //     user.studentCode,
            //     Number(selectedValue),
            //     "BOTTLE"
            //   );
            //   setCartSuccess(true);
            //   return;
            // }

            // alert(
            //   "Pour des raisons de stocks et d'équité (et pour ton foie),\n La quantité est limitée à six bières par commande et trois par produits"
            // );
          });
      });
  };

  if (!product) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <h1>Loading...</h1>
      </div>
    );
    // todo : Make it look nicer and usefull in case of long api response
  }

  return (
    (product.shopVisible && product.disponibility > 0 && (
      <div className={"bg-odm-cream"}>
        <Layout>
          <div className="flex flex-grow items-center justify-between flex-col">
            <div
              className={clsx(
                "flex flex-col-reverse flex-grow w-full justify-center items-center my-8",
                "lg:flex-row lg:justify-around lg:px-24"
              )}
            >
              <div className={"flex-1"}>
                <div className="text-justify mx-8 ">
                  <div className={"flex p-4 lg:justify-start justify-center items-end"}>
                    <h2 className={"text-3xl flex-1 lg:flex-none"}>{capitalize(product.name)}</h2>
                    <h2 className={"text-3xl ml-12 flex-1 lg:flex-none"}>
                      {
                        (Number(product.price) +
                          Number(product.depositPrice)) as number
                      }{" "}
                      €
                    </h2>
                  </div>
                  <div className="flex items-center justify-center p-4 text-justify flex-col">
                    <p>{product.description} (*1)</p>
                  </div>
                </div>
                {addingItem ? (
                  <div className={"flex justify-center mt-8"}>
                    <Oval
                      height="80"
                      width="80"
                      color="#fe9200"
                      ariaLabel="oval-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                      secondaryColor="#fe9200"
                    />
                  </div>
                ) : (
                  <div className="flex flex-col items-center justify-center mt-4">
                    <p>Quantité</p>
                    <Selector
                      values={[
                        { value: "1", label: "1" },
                        { value: "2", label: "2" },
                        { value: "3", label: "3" },
                      ]}
                      onValueChange={handleValueChange}
                      value={selectedValue}
                    />
                    <Button
                      text="Ajouter au panier"
                      onClick={handleClick}
                      className={"mt-4"}
                    />

                    {cartSuccess ? (
                      <p className={"text-success-dark mt-8"}>
                        La commande est enregistrée !
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                )}
              </div>

              <div
                className={clsx(
                  "w-72 aspect-square cursor-pointer mb-8 lg:mb-0 lg:w-96 rounded-xl flex-1 lg:ml-8"
                )}
                style={{
                  backgroundImage: `url(${getProductBaseImage(product)})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
            </div>
            <div className="text-center p-8">
              <p>
                (*1) Conformément à l'article 3342-1-3 du code de la santé
                publique, nous nous réservons le droit de vous demander votre
                carte étudiante ou carte d'identité. Dans le cas d'un achat
                alors que vous êtes mineur, le produit vous sera remboursé.
              </p>
            </div>
          </div>
        </Layout>
      </div>
    )) || (
      <div className={"min-h-full bg-odm-cream pb-8"}>
        <Layout>
          <div className="mx-8 flex items-center justify-center h-[60vh]">
            <h1 className=" text-center text-5xl">Produit non disponible</h1>
          </div>
        </Layout>
      </div>
    )
  );
}

export default ProductPage;
