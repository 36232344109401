import { AuthInfo } from "../model/AuthInfo";
import { ApiService, tokenSubject } from "./apiService";
import {BehaviorSubject} from "rxjs";
import { User } from "../model/User";

const userSubject = new BehaviorSubject<User | null>(null);

const connectionAttempted = new BehaviorSubject<boolean>(false)

export function connect(authInfo: AuthInfo) {
  return ApiService.post("/auth", authInfo);
}

export function getTokenFromRefreshToken(refreshToken: string) {
  return ApiService.post("/auth/refresh", { refreshToken: refreshToken });
}

export function getCurrentUser() {
  return ApiService.get("/students/me");
}

export function saveBeerClicks(amount : number){
  return ApiService.post("/students/beer_clicker", {amount : amount});
}

export function getLeaderboard(){
  return ApiService.get("/students/beer_leaderboard");
}

export function disconnect() {
  localStorage.removeItem("refresh-token");
  userSubject.next(null);

}

export default function getUserSubject() {
  return userSubject;
}

export function getConnectionAttempted(){
  return connectionAttempted;
}

export function refreshToken() {  //this shouldn't be here but don't know where to put it
  const refreshToken = localStorage.getItem("refresh-token");
  if(!refreshToken){
    return
  }
  return getTokenFromRefreshToken(refreshToken).then((response) => {
    if(!response.ok){
      disconnect();
      return
    }
    return response.json().then((data) => {
      tokenSubject.next(data.token);
      localStorage.setItem("refresh-token", data.refreshToken);
    });
  });
}