import React, {FC} from "react";
import {Product} from "../../../model/Product";
import {getImageByName} from "../../../service/image.service";
import {capitalize} from "../../../helper/helper";
import {useNavigate} from "react-router-dom";
import {getProductBaseImage} from "../../../service/product.service";

export type ProductAdminProps = {
    product : Product;
}

const ProductAdmin: FC<ProductAdminProps> = ({product}) => {
    const navigate = useNavigate();


    return(
      <div
          className={"cursor-pointer bg-opacity-40 bg-grey rounded-xl flex items-center w-1/2 justify-around text-xl p-2 my-2 border"}
          onClick={() => navigate("/admin/product/"+product.id)}
      >

          <div
              className={"lg:w-28 sm:w-1/2 xs:w-1/2 aspect-square rounded-full"}
              style={{
                  backgroundImage: `url(${getProductBaseImage(product)})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
              }}
          ></div>
          <h2>Nom : {capitalize(product.name)}</h2>
          <h2>Quantité : {product.disponibility}</h2>
          <h2>Visible : {product.shopVisible? "Oui" : "Non"}</h2>
      </div>
    );
}

export default ProductAdmin;