import {FC, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {Product} from "../../../model/Product";
import getProductsSubject, {getProducts} from "../../../service/product.service";
import getUserSubject, {getConnectionAttempted} from "../../../service/user.service";
import Layout from "../../../../components/UI/Layout/Layout";
import {capitalize} from "../../../helper/helper";
import TextField from "../../../../components/UI/TextField/TextField";
import {useForm} from "react-hook-form";
import {AuthentificationForm} from "../../../forms/AuthentificationForm";
import FileUploader from "../../../../components/UI/FileUploader/FileUploader";
import {Image} from "../../../model/Image";

export type ProductAdminDetailProps = {

}

const ProductAdminDetail: FC<ProductAdminDetailProps> = () => {
    const { pathname } = useLocation();
    const productId = pathname.split("/").at(-1);

    const [product, setProduct] =
        useState<Product | null>(null)

    const [files, setFiles] =
        useState<File[]>([])

    const getProductImage = () : Image[] => {
        if(product && product.images){
            return product.images
        }
        return [];
    }
    const [image, setImages] = useState<Image[]>(getProductImage)

    const {
        handleSubmit,
        control,
        formState:{errors}
    } =
        useForm<AuthentificationForm>({
            defaultValues:{
                id:"",
                password:""
            }
        })


    useEffect(() => {
        getUserSubject().subscribe(u => {
            console.log(getConnectionAttempted().value)
            if(!u || !productId){
                return
            }
            getProducts().then(response => response.json())
                .then(data => {
                    const products = data as Product[]
                    //getProductsSubject().next(products)
                    const id = parseInt(productId);
                    products.forEach(p => {
                        if(p.id===id){
                            setProduct(p);
                        }
                    })
                })
                .catch((error) => console.log(error))


        })
    }, []);

    return (
        <div className={"min-h-full bg-odm-cream pb-8"}>
            <Layout>
                <div className={"flex justify-center mt-12"}>
                    <h1 className={"text-6xl"}>
                        {product? capitalize(product.name) : ""}
                    </h1>
                </div>
                <form>
                    <div>
                        Name
                        <TextField/>
                    </div>
                    <div>
                        Disponibility
                        <input type={"number"}/>
                    </div>
                    <div>
                        Price
                        <input type={"number"}/>
                    </div>
                    <div>
                        Deposit Price
                        <input type={"number"}/>
                    </div>
                    <div>
                        Description
                        <textarea />
                    </div>
                </form>
                <div className={"w-1/3"}>
                    Upload and assign an  image
                    <FileUploader
                        multiple={false}
                        onValueChange={(files : File[]) => {
                            setFiles(files)
                        }}
                        validExtensions={["JPG","PNG","JPEG"]}
                    />
                </div>
            </Layout>
        </div>
    )
}

export default ProductAdminDetail;