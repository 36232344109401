import { useLocation, useNavigate } from "react-router-dom";
import {
  createCheckout,
  getPaymentStatus,
} from "../../service/payment.service";
import "./PaymentPageNoDeposit.scss";
import { useEffect, useState } from "react";
import { Payment } from "../../model/Payment";
import Button from "../../../components/UI/Button/Button";
import Layout from "../../../components/UI/Layout/Layout";
import getUserSubject from "../../service/user.service";
import { Subscription } from "rxjs";
import { Oval } from "react-loader-spinner";

export function PaymentPageNoDeposit() {
  const paymentId = useLocation().pathname.split("/").at(-1);
  const [isLinkDisplayed, setIsLinkDisplayed] = useState(false);
  const [link, setLink] = useState("");
  const [user, setUser] = useState(getUserSubject().value);
  const [loading, setLoading] = useState(false);
  const [userSubscription, setUserSubscription] = useState<Subscription | null>(
    null
  );
  const [redirectLoading, setRedirectLoading] = useState(false);

  let intervalId: NodeJS.Timeout;

  const navigate = useNavigate();

  useEffect(() => {
    const handleUnload = () => {
      window.addEventListener("beforeunload", handleUnload);

      return () => {
        clearInterval(intervalId);
        if (userSubscription) {
          userSubscription.unsubscribe();
        }
        window.removeEventListener("beforeunload", handleUnload);
      };
    };
  }, []);

  useEffect(() => {
    const sub = getUserSubject().subscribe((u) => {
      if (!u) return;
      setUser(u);
    });
    setUserSubscription(sub);
    if (!!link) window.open(link);
  }, []);

  function payOnClick() {
    setLoading(true);
    createCheckout(paymentId!)
      .then((response) => response.json())
      .then((data) => {
        const link = data as Payment;
        setLink(link.helloassoCheckoutUrl);
        window.open(link.helloassoCheckoutUrl);
        setIsLinkDisplayed(true);
      })
      .catch((error) => console.log(error));


    intervalId = setInterval(() => {
      getPaymentStatus(paymentId!)
        .then((response) => response.json())
        .then((data) => {
          const payment = data as Payment;
          if (payment.paymentStatus == "PAID") {
            navigate("/success");
            clearInterval(intervalId);
          }
        })
        .catch((error) => console.log(error));
    }, 5000);
  }

  return (
    <div className={"min-h-full bg-odm-cream pb-8"}>
      <Layout>
        {loading ? (
          <div className={"flex items-center justify-center mt-8 flex-col"}>
            <Oval
              height="160"
              width="160"
              color="#fe9200"
              ariaLabel="oval-loading"
              wrapperStyle={{}}
              wrapperClass=""
              secondaryColor="#fe9200"
            />
            <a href={link} target="_blank" className={"underline mt-8"}>
              Lien vers le payement
            </a>
          </div>
        ) : (
          <div>
            <div
              className={
                "flex flex-col items-center justify-center text-center gap-5  "
              }
            >
              <h1 className={"text-2xl"}>Merci de votre achat</h1>
              <h2 className={"text-2xl"}>
                Vous allez être redirigé vers la page de paiement
              </h2>
              <h3 className={"text-2xl"}>
                Si vous n'êtes pas redirigé, cliquez sur le bouton ci-dessous
              </h3>
              <div>
                <Button text={"Payer"} onClick={() => payOnClick()}></Button>
              </div>
            </div>
          </div>
        )}
      </Layout>
    </div>
  );
}
