import React, { FC, useEffect, useState } from "react";
import Header from "../Header/Header";
import { User } from "../../../app/model/User";
import getUserSubject, { getConnectionAttempted } from "../../../app/service/user.service";
import { useLocation, useNavigate } from "react-router-dom";

type LayoutProps = {
  children: React.ReactNode;
  displayCart?: boolean;
};

const Layout: FC<LayoutProps> = ({ children, displayCart }) => {
  const [user, setUser] = useState<User | null>(null);
  const { pathname } = useLocation();

  const value = pathname.split("/").at(1);

  const navigate = useNavigate();

  useEffect(() => {
    getUserSubject().subscribe((user) => {
      setUser(user);
      if (!user && getConnectionAttempted().value) {
        navigate("/authentification");
      }
    });


  }, []);
  return (
    <div className="flex flex-col min-h-screen">
      <Header
        headerLink={[
          { value: "home", label: "Accueil" },
          { value: "boutique", label: "Boutique" },
          { value: "contact", label: "Contact" },
        ]}
        user={user}
        background={value === "home"}
        displayCart={displayCart}
      ></Header>
      {children}
    </div>
  );
};

export default Layout;
