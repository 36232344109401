import { FC, useEffect, useState } from "react";
import { User } from "../../../model/User";
import getUserSubject, { getLeaderboard } from "../../../service/user.service";


type LeaderboardProps = {
 leaderBoard : User[]
};

const Leaderboard : FC<LeaderboardProps> = ({leaderBoard}) => {


  const [student, setStudent] = useState<User|null>(null)

  useEffect(() => {
    getUserSubject().subscribe(u => {
      if(!u){
        return
      }
      setStudent(u)

    })


  }, []);

  const getStudentPos = () => {
    if(!student){
      return -1
    }
    return leaderBoard.indexOf(leaderBoard.filter(s => s.studentCode===student.studentCode)[0])
  }

  const studentLeaderBoard = () => {
    if(!student){
      return (<></>)
    }
    const pos = getStudentPos()+1

    if(pos <= 5){
      return (<></>)
    }

    return (
      <tr key={pos}>
        <td className="px-6 py-4 bg-[#fff5e8] rounded-bl-xl truncate max-w-40">{pos}</td>
        <td className="px-6 py-4 bg-[#fff5e8] truncate max-w-40">{student.name}</td>
        <td className="px-6 py-4 bg-[#fff5e8] rounded-br-xl truncate max-w-40">{student.familyName}</td>
      </tr>
    )
  }

  return (
    <div className={""}>
      <table className="divide-y divide-gray-200">
        <thead>
        <tr className={""}>
          <th className="px-6 py-3 bg-[#fe9200] text-left text-xs font-medium text-white uppercase tracking-wider rounded-tl-xl">Rang</th>
          <th className="px-6 py-3 bg-[#fe9200] text-left text-xs font-medium text-white uppercase tracking-wider">Prénom</th>
          <th className="px-6 py-3 bg-[#fe9200] text-left text-xs font-medium text-white uppercase tracking-wider rounded-tr-xl">Nom de Famille</th>
        </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
        {
          leaderBoard.map((student, index) => {
            if(index>=5){
              return(<></>)
            }
            if( (getStudentPos()+1==5 && index==4) || (getStudentPos()+1<5 && index==4) ){
              return(
                <tr key={index}>
                  <td className="px-6 py-4 bg-[#fff5e8] rounded-bl-xl truncate max-w-40">{index + 1}</td>
                  <td className="px-6 py-4 bg-[#fff5e8] truncate max-w-40">{student.name}</td>
                  <td className="px-6 py-4 bg-[#fff5e8] rounded-br-xl truncate max-w-40">{student.familyName}</td>
                </tr>
              )
            }

            return(
              <tr key={index}>
                <td className="px-6 py-4 bg-[#fff5e8]">{index + 1}</td>
                <td className="px-6 py-4 truncate max-w-40 bg-[#fff5e8]">{student.name}</td>
                <td className="px-6 py-4 truncate max-w-40 bg-[#fff5e8]">{student.familyName}</td>
              </tr>
            )
          })
        }
        {getStudentPos()+1>5?
          <tr>
            <td className="px-6 py-4 bg-[#fff5e8] truncate max-w-40"></td>
            <td className="px-6 py-4 bg-[#fff5e8] truncate max-w-40">...</td>
            <td className="px-6 py-4 bg-[#fff5e8] truncate max-w-40"></td>
          </tr>
          :
          <></>
        }
        {studentLeaderBoard()}
        </tbody>
      </table>
    </div>
  )
}

export default Leaderboard;