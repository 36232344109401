import React, {FC, useRef, useState} from 'react';
import Button from "../Button/Button";
import clsx from "clsx";


type FileUploaderProps = {
    onValueChange : Function
    validExtensions : String[];
} & (
    | (React.ComponentPropsWithoutRef<'input'>)
    )

const FileUploader: FC<FileUploaderProps> = ({onValueChange,validExtensions,...props}) => {
    const inputFile = useRef<HTMLInputElement | null>(null);
    const divRef = useRef<HTMLInputElement | null>(null);

    const [files,setFiles] = useState<File[]>([])


    React.useEffect(() => {
        if(!divRef || !divRef.current){
            return;
        }
        divRef.current.addEventListener('dragover', handleDragOver);
        divRef.current.addEventListener('drop', handleDrop);

        return () => {
            if(!divRef || !divRef.current){
                return;
            }
            divRef.current.removeEventListener('dragover', handleDragOver);
            divRef.current.removeEventListener('drop', handleDrop);
        };
    }, []);

    const handleDragOver = (e :any) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e:any) => {
        e.preventDefault();
        e.stopPropagation();

        const {files} = e.dataTransfer;

        const fileList : File[] = [];
        for(let i = 0;i<files.length;i++){
            fileList.push(files.item(i))
        }
        setFiles(fileList.filter(f => isValidExtension(f)))

        if (files && files.length) {
            onValueChange(fileList.filter(f => isValidExtension(f)))
        }
    };

    const handleFileSelected = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const newFiles = e.target.files
        console.log(newFiles)
        if(!newFiles){
            return;
        }

        const fileList : File[] = [];

        for(let i = 0;i<newFiles.length;i++){
            if(newFiles.item(i)!==null){
                let f = newFiles.item(i) as File
                fileList.push(f)
            }

        }
        setFiles(fileList.filter(f => isValidExtension(f)))
        onValueChange(fileList.filter(f => isValidExtension(f)))
    }

    const isValidExtension = (file :File) =>{
        const ext = file.name.split(".").at(-1);
        if(!ext){
            return false;
        }
        return validExtensions.includes(ext.toUpperCase())
    }



    return(
        <div
            ref={divRef}
            className={"flex flex-col rounded-xl border-odm-orange border-2 justify-center items-center  h-56 bg-grey"}>
            <div>
                Drop a file here or
            </div>
            <div className={"mt-4"}>
                <Button
                    text={"Browse"}
                    onClick={()=>inputFile.current?.click()}/>
            </div>
            <div className={"flex justify-center items-center"}>
                {files.map(f => {
                    const index = files.indexOf(f);
                    if(index<2){
                        return (
                            <div className={
                                clsx(
                                    "flex mx-1 w-fit rounded-full mt-4 text-center",
                                    isValidExtension(f) ?"bg-success" : "bg-error text-white",

                                )
                            }>
                                <p className={"p-2 text-xs"}>{f.name}</p>
                            </div>


                        )
                    }if(index===2){
                        return (<p className={"ml-2"}>...</p>)
                    }
                    }
                )}
            </div>
            <input
                className={"hidden"}
                type={'file'}
                multiple={props.multiple}
                value={props.value}
                onChange={handleFileSelected}
                ref={inputFile}
            />

        </div>
    );
}

export default FileUploader;
